import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('/fichierDgi');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = {page: 1, size: 7, texte: ''}) {
        let { data } = await client.get('/fichierDgi/'+payload.page+'/'+payload.size, {params: {texte: payload.texte}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async getRaisonSocial(cxt, ifu) {
        let data  = await client.get(`/fichierDgi/${ifu}`);
        cxt.commit("SET_ONE", data)
        return data;
    },
    async updateDonnee(cxt, payload) {
        let { data } = await client.put('fichierDgi/edit/'+payload.id, payload);    
        return data;    
    },
    
    
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
