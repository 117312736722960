import client from '@/services/';

const state = () => ({
    secteur: [],
    section: [],
    division: [],
    groupe: [],
    classe: [],
    sousClasse: [],
});

const getters = {
    secteur: state => state.secteur,
    section: state => state.section,
    division: state => state.division,
    groupe: state => state.groupe,
    classe: state => state.classe,
    sousClasse : state => sousClasse,
    produit : state => produit
};

const mutations = {
    SET_SECTEUR: (state, obj) => {
        state.secteur = obj;
    },
    SET_SECTION: (state, obj) => {
        state.section = obj;
    },
    SET_DIVISION: (state, obj) => {
        state.division = obj;
    },
    SET_GROUPE: (state, obj) => {
        state.groupe = obj;
    },
    SET_CLASSE: (state, obj) => {
        state.classe = obj;
    },
    SET_SOUS_CLASSE: (state, obj) => {
        state.sousClasse = obj;
    },
    SET_PRODUIT: (state, obj) => {
        state.produit = obj;
    },
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
};

const actions = {
    async getSecteur(cxt, payload) {
        let { data } = await client.get('echelonActivite/secteur');
        cxt.commit("SET_SECTEUR", data);
        return data; 
    },
    async getSection(cxt, secteurId) {
        let { data } = await client.get('echelonActivite/section/'+secteurId);
        cxt.commit("SET_SECTION", data);
        return data;
    },
    async getDivision(cxt, sectionId) {
        let { data } = await client.get('echelonActivite/division/'+sectionId);
        cxt.commit("SET_DIVISION", data);
        return data;
    },
    async getGroupe(cxt, divisionId) {
        let { data } = await client.get('echelonActivite/groupe/'+divisionId);
        cxt.commit("SET_GROUPE", data);
        return data;
    },
    async getClasse(cxt, groupeId) {
        let { data } = await client.get('echelonActivite/classes/'+groupeId);
        cxt.commit("SET_CLASSE", data);
        return data;
    },
    async getsousClasse(cxt, classeId) {
        let { data } = await client.get('echelonActivite/sousClasses/'+classeId);
        cxt.commit("SET_SOUS_CLASSE", data);
        return data;
    },
    async getProduit(cxt, sousClasseId) {
        let { data } = await client.get('echelonActivite/produit/'+sousClasseId);
        cxt.commit("SET_PRODUIT", data);
        return data;
    },
    async getProduitLike(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('echelonActivite/produit/like/'+payload.page, {params: {texte: payload.texte}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
