const connexion = [
  {
    path: "/",
    name: "connexion",
    component: () => import("@/views/Signin"),
  },
];



const admin = [
  {
    path: "/",
    //meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "dashboard",
    component: () => import("@/views/Dashboard"),
  },
  {
    path: "/demandes/backoffice",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "backOfficeDemandes",
    component: () => import("@/views/services/demandes"),
  },
  {
    path: "/demande/backoffice/:id",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "backOfficeDemande",
    component: () => import("@/views/services/demande"),
  },
  {
    path: "/grille/tarifaire",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "grilleTarifaire",
    component: () => import("@/views/services/grille-tarifaire"),
  },
  {
    path: "/services",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "services",
    component: () => import("@/views/services/service"),
  },
  {
    path: "/type/services",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "typeServices",
    component: () => import("@/views/services/typeServices"),
  },
  {
    path: "/clients/membres",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "clientPaid",
    component: () => import("@/views/entreprise/CompteEntrepriseMembre"),
  },
  {
    path: "/entreprise/creer",
    meta: { requiresPermission: ['ROLE_ADMIN', 'ROLE_MEMBRE'] },
    name: "entrepriseCreer",
    component: () => import("@/views/entreprise/CompteEntreprise"),
  },
  {
    path: "/utilisateurs/assigner",
    meta: { requiresPermission: "PRIVILEGE_ASSIGNATION" },
    name: "assignation",
    component: () => import("@/views/assignation/Assignation"),
  },
  {
    path: "/portefeuille/utilisateur",
    meta: { requiresPermission: "PRIVILEGE_PORTEFEUILLE" },
    name: "portefeuilleUtilisateur",
    component: () => import("@/views/assignation/clientUser"),
  },
  {
    path: "/clients/contacts",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "contacts",
    component: () => import("@/views/contact/Contact"),
  },
  {
    path: "/entreprise/importer",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "entrepriseImporter",
    component: () => import("@/views/entreprise/ImportEntreprise"),
  },
  {
    path: "/agents/ccib",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "compteCcib",
    component: () => import("@/views/utilisateur/AgentCCIB"),
  },
  {
    path: "/ambassade",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_AMBASSADE" },
    name: "ambassadeCreer",
    component: () => import("@/views/ambassade/Ambassade.vue")
  },
  {
    path: "/etatEntreprise",
    /* meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_ETAT_ENTREPRISE "}, */
    name: "etatEntreprise",
    component: () => import("@/views/ambassade/etatEntreprise.vue")
  },
  {
    path: "/typeEvenement",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_TY_EVENEMENT" },
    name: "typeEvenementCreer",
    component: () => import("@/views/typeEvenement/typeEvenement.vue")
  },
  {
    path: "/typeEchange",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_TY_ECHANGE" },
    name: "typeEchangeCreer",
    component: () => import("@/views/typeEchange/typeEchange.vue")
  },
  {
    path: "/typeActualite",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_TY_ACTUALITE" },
    name: "typeActualiteCreer",
    component: () => import("@/views/typeActualite/typeActualite.vue")
  },
  {
    path: "/typeActivite",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_TY_ACTIVITE" },
    name: "typeActiviteCreer",
    component: () => import("@/views/typeActivite/typeActivite.vue")
  },
  {
    path: "/region",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_REGION" },
    name: "regionCreer",
    component: () => import("@/views/region/Region.vue")
  },
  {
    path: "/permission",
    meta: { requiresPermission: 'PRIVILEGE_UTILISATEUR_PERMISSION' },
    name: "permissionCreer",
    component: () => import("@/views/userGestion/Permission.vue")
  },
  {
    path: "/formeJuridique",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_FORME_JURIDIQUE" },
    name: "formeJuridiqueCreer",
    component: () => import("@/views/formeJuridique/formeJuridique.vue")
  },
  {
    path: "/fonction",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_FONCTION" },
    name: "fonctionCreer",
    component: () => import("@/views/fonction/Fonction.vue")
  },
  {
    path: "/typeBesoin",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_TY_BESOIN" },
    name: "typeBesoinCreer",
    component: () => import("@/views/typeBesoin/typeBesoin.vue")
  },
  {
    path: "/role",
    meta: { requiresPermission: 'PRIVILEGE_UTILISATEUR_ROLE' },
    name: "roleCreer",
    component: () => import("@/views/userGestion/role.vue")
  },
  {
    path: "/besoin",
    meta: { requiresPermission: "PRIVILEGE_REFERENTIEL_BESOIN" },
    name: "besoinCreer",
    component: () => import("@/views/besoin/Besoin.vue")
  },
  {
    path: "/secteurActivite",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "secteurActiviteCreer",
    component: () => import("@/views/secteurActivite/secteurActivite.vue")
  },
  {
    path: "/activiteElection",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "activiteElection",
    component: () => import("@/views/secteurActivite/activiteElection.vue")
  },
  {
    path: "/categorieActivite",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "categorieActiviteCreer",
    component: () => import("@/views/categorieActivite/categorieActivite.vue")
  },
  {
    path: "/branche",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "brancheCreer",
    component: () => import("@/views/branche/branche.vue")
  },
  {
    path: "/activiteOhada",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "activiteOhadaCreer",
    component: () => import("@/views/activiteOhada/activiteOhada.vue")
  },
  {
    path: "/activiteEntreprise",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "activiteEntrepriseCreer",
    component: () => import("@/views/activiteEntreprise/activiteEntreprise.vue")
  },
  {
    path: "/sousActivite",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "sousActiviteCreer",
    component: () => import("@/views/sousActivite/sousActivite.vue")
  },
  {
    path: "/personne",
    meta: { requiresPermission: "PRIVILEGE_ADMINISTRATION_PERSONNE" },
    name: "contactCreer",
    component: () => import("@/views/administration/contact/ContactAdministration.vue")
  },
  {
    path: "/dirigeant",
    //meta: { requiresPermission: "PRIVILEGE_ADMINISTRATION_DIRIGEANT_AHILIDO" },
    name: "dirigeantCreer",
    component: () => import("@/views/administration/dirigeant/Dirigeant.vue")
  },
  {
    path: "/responsable",
    //meta: { requiresPermission: "PRIVILEGE_ADMINISTRATION_DIRIGEANT_MEMBRE" },
    name: "responsableCreer",
    component: () => import("@/views/administration/dirigeant/Responsable.vue")
  },
  {
    path: "/dirigeant/entreprise",
    // meta: { requiresPermission: "PRIVILEGE_ADMINISTRATION_DIRIGEANT_MEMBRE" },
    name: "dirigeantEntreprise",
    component: () => import("@/views/administration/dirigeant/DirigeantEntreprise.vue")
  },
  {
    path: "/upload/File",
    meta: { requiresPermission: "PRIVILEGE_ADMINISTRATION_CHARGEMENT" },
    name: "uploadFile",
    component: () => import("@/views/administration/fichier/Fichier.vue")
  },
  {
    path: "/comptes",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "compte",
    component: () => import("@/views/userGestion/compte.vue")
  },
  {
    path: "/utilisateur",
    meta: { requiresPermission: 'PRIVILEGE_UTILISATEUR_UTILISATEUR' },
    name: "utilisateur",
    component: () => import("@/views/userGestion/utilisateur.vue")
  },
  {
    path: "/ambassadeur",
    meta: { requiresPermission: 'PRIVILEGE_UTILISATEUR_UTILISATEUR' },
    name: "ambassadeur",
    component: () => import("@/views/userGestion/inscriptionAmbassade.vue")
  },
  {
    path: "/ahilido",
    meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_AHILIDO1.0' },
    name: "ahilido",
    component: () => import("@/views/LesEntreprises/ahilido.vue")
  },
  {
    path: "/referentiel",
    meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE-REFERENTIEL' },
    name: "referentiel",
    component: () => import("@/views/LesEntreprises/referentiel.vue")
  },
  {
    path: "/membres",
    meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_MEMBRE' },
    name: "membres",
    component: () => import("@/views/LesEntreprises/membres.vue")
  },
  {
    path: "/consulterReferentiel",
    meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE-REFERENTIEL' },
    name: "consulterReferentiel",
    component: () => import("@/views/LesEntreprises/consulterReferentiel.vue")
  },
  {
    path: "/consulterMembre",
    meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_MEMBRE' },
    name: "consulterMembre",
    component: () => import("@/views/LesEntreprises/consulterMembre.vue")
  },
  {
    path: "/entreprises",
    //meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_ENTREPRISE' },
    name: "entreprises",
    component: () => import("@/views/LesEntreprises/entreprises.vue")
  },
  {
    path: "/donneeFinanciere",
    //meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_DONNEES_FINANCIERES' },
    name: "donneeFinanciere",
    component: () => import("@/views/LesEntreprises/donneeFinancier.vue")
  },
  {
    path: "/section",
    //meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "section",
    component: () => import("@/views/activiteNapBenin/section.vue")
  },
  {
    path: "/division",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "division",
    component: () => import("@/views/activiteNapBenin/division.vue")
  },
  {
    path: "/groupe",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "groupe",
    component: () => import("@/views/activiteNapBenin/groupe.vue")
  },
  {
    path: "/classe",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "classe",
    component: () => import("@/views/activiteNapBenin/classe.vue")
  },
  {
    path: "/sousClasse",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "sousClasse",
    component: () => import("@/views/activiteNapBenin/sousClasse.vue")
  },
  {
    path: "/produit",
    // meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "produit",
    component: () => import("@/views/activiteNapBenin/produit.vue")
  },
  {
    path: "/status",
    meta: { requiresPermission: "PRIVILEGE_RS_STATUT" },
    name: "status",
    component: () => import("@/views/services/status.vue")
  },
  {
    path: "/modeTraitement",
    meta: { requiresPermission: "PRIVILEGE_RS_MODE_TRAITEMENT" },
    name: "modeTraitement",
    component: () => import("@/views/services/modeTraitement.vue")
  },
  {
    path: "/moyenPaiement",
    meta: { requiresPermission: "PRIVILEGE_RS_MODE_PAIEMENT" },
    name: "moyenPaiement",
    component: () => import("@/views/services/moyenPaiement.vue")
  },
  {
    path: "/naturePiece",
    meta: { requiresPermission: "PRIVILEGE_RS_NATURE_PIECE" },
    name: "naturePiece",
    component: () => import("@/views/services/naturePiece.vue")
  },
  {
    path: "/statistiques",
    meta: { requiresPermission: "PRIVILEGE_STATISTIQUE" },
    name: "statistiques",
    component: () => import("@/views/statistiques/Statistiques.vue")
  },
  {
    path: "/requetes",
    //meta: { requiresPermission: 'PRIVILEGE_REQUETE_MEMBRE' },
    name: "requeteMembre",
    component: () => import("@/views/requetes/requeteMembre.vue")
  },
  {
    path: "/dirigeantSexe",
    //meta: { requiresPermission: 'PRIVILEGE_REQUETE_REFERENTIEL' },
    name: "dirigeantSexe",
    component: () => import("@/views/requetes/dirigeantSexe.vue")
  },
  {
    path: "/dirigeantEntreprise",
    // meta: { requiresPermission: 'PRIVILEGE_REQUETE_MEMBRE' },
    name: "dirigeantEntreprises",
    component: () => import("@/views/requetes/ResponsableEntreprise.vue")
  },
  {
    path: "/requeteDirigeant",
    // meta: { requiresPermission: 'PRIVILEGE_REQUETE_MEMBRE' },
    name: "requeteDirigeant",
    component: () => import("@/views/requetes/ResponsableDirigeant.vue")
  },
  {
    path: "/requeteEntreprise",
    // meta: { requiresPermission: 'PRIVILEGE_REQUETE_MEMBRE' },
    name: "requeteEntreprise",
    component: () => import("@/views/requetes/RequeteEntreprise.vue")
  },
  {
    path: "/dirigeantFontion",
    // meta: { requiresPermission: 'PRIVILEGE_REQUETE_MEMBRE' },
    name: "dirigeantFontion",
    component: () => import("@/views/requetes/ResponsableFonction.vue")
  },
  {
    path: "/requetePays",
    // meta: { requiresPermission: 'PRIVILEGE_REQUETE_MEMBRE' },
    name: "requetePays",
    component: () => import("@/views/requetes/RequetePays.vue")
  },
  {
    path: "/sourceFinancement",
    meta: { requiresPermission: 'PRIVILEGE_SF_SOURCE_FINANCEMENT' },
    name: "sourceFinancement",
    component: () => import("@/views/servicesFournis/sourceFinance.vue")
  },
  {
    path: "/programme",
    meta: { requiresPermission: "PRIVILEGE_SF_PROGRAMME" },
    name: "programme",
    component: () => import("@/views/servicesFournis/programme.vue")
  },
  {
    path: "/categorieSaf",
    meta: { requiresPermission: 'PRIVILEGE_SF_CATEGORIE' },
    name: "categorieSaf",
    component: () => import("@/views/servicesFournis/categorieSaf.vue")
  },
  {
    path: "/saf",
    //meta: { requiresPermission: 'PRIVILEGE_SF_CATEGORIE' },
    name: "saf",
    component: () => import("@/views/servicesFournis/saf.vue")
  },
  {
    path: "/serviceFourni",
    meta: { requiresPermission: "PRIVILEGE_SF_SERVICE_A_FOURNIR" },
    name: "serviceFourni",
    component: () => import("@/views/servicesFournis/serviceFourni.vue")
  },
  {
    path: "/serviceFourniEntreprise",
    // meta: { requiresPermission: "PRIVILEGE_SF_SERVICE_A_FOURNIR" },
    name: "serviceFourniEntreprise",
    component: () => import("@/views/servicesFournis/serviceFourniEntreprise.vue")
  },
  {
    path: "/groupement",
    meta: { requiresPermission: "PRIVILEGE_MENU_GROUPEMENT" },
    name: "groupement",
    component: () => import("@/views/servicesFournis/groupement.vue")
  },
  {
    path: "/mandature",
    meta: { requiresPermission: "PRIVILEGE_MENU_MANDATURE" },
    name: "mandature",
    component: () => import("@/views/servicesFournis/mandature.vue")
  },
  {
    path: "/partenaire",
    //meta: { requiresPermission: "PRIVILEGE_MENU_MANDATURE" },
    name: "partenaire",
    component: () => import("@/views/servicesFournis/partenaire.vue")
  },
  {
    path: "/projet",
    meta: { requiresPermission: "PRIVILEGE_SF_PROJET" },
    name: "projet",
    component: () => import("@/views/servicesFournis/projet.vue")
  },
  {
    path: "/devise",
    meta: { requiresPermission: "PRIVILEGE_SF_DEVISE" },
    name: "devise",
    component: () => import("@/views/servicesFournis/devise.vue")
  },
  {
    path: "/referent",
    meta: { requiresPermission: "PRIVILEGE_SF_REFERENT" },
    name: "referent",
    component: () => import("@/views/servicesFournis/referent.vue")
  },
  {
    path: "/journalisation",
    meta: { requiresPermission: 'PRIVILEGE_JOURNALISATION' },
    name: "journalisation",
    component: () => import("@/views/journalisation/journalisation.vue")
  },
  {
    path: "/journalisationAdmin",
    meta: { requiresPermission: 'PRIVILEGE_JOURNALISATION' },
    name: "journalisationAdmin",
    component: () => import("@/views/journalisation/journalisationAdmin.vue")
  },
  {
    path: "/profil",
    //meta: { requiresPermission: ['ROLE_ADMIN'] },
    name: "profil",
    component: () => import("@/views/userGestion/profilUser.vue")
  },
  {
    path: "/fichierLogs",
    meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_RAPPORT_IMPORTATION' },
    name: "fichierLogs",
    component: () => import("@/views/journalisation/fichierLogs.vue")
  },
  {
    path: "/verificationDGI",
    // meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_RAPPORT_IMPORTATION' },
    name: "verificationDGI",
    component: () => import("@/views/requetes/verificationDGI.vue")
  },
  {
    path: "/requeteDirigeants",
    // meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_RAPPORT_IMPORTATION' },
    name: "requeteDirigeants",
    component: () => import("@/views/requetes/dirigeantEntrepriseRequete.vue")
  },
  {
    path: "/requeteElection",
    // meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_RAPPORT_IMPORTATION' },
    name: "requeteElection",
    component: () => import("@/views/autresRequetes/requeteElection.vue")
  },
  {
    path: "/requeteNature",
    // meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_RAPPORT_IMPORTATION' },
    name: "requeteNature",
    component: () => import("@/views/autresRequetes/requeteNature.vue")
  },
  {
    path: "/contribuable",
    // meta: { requiresPermission: 'PRIVILEGE_ANNUAIRE_RAPPORT_IMPORTATION' },
    name: "contribuable",
    component: () => import("@/views/autresRequetes/contribuable.vue")
  },


];

export default [
  {
    path: "/",
    component: () => import("@/views/Signin"),
    children: connexion,
  },
  {
    path: "/admin",
    component: () => import("@/views/espace/Admin"),
    //meta: { requiresPermission: ['ROLE_ADMIN'] },
    children: admin,
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("@/views/PasswordForget"),
  },

];
